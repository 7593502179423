import type { FC } from 'react';
import { memo } from 'react';
import { type ChubbsProps } from '@/components/ui/Chubbs/chubbs.typedefs';
import {
  ChubbsWrapper,
} from '@/components/ui/Chubbs/ChubbsWrapper';
import { colorTokens } from '@/components/ui/colorTokens';

export const ChubbsLikeLight: FC<ChubbsProps> = memo((props) => (
  <ChubbsWrapper initialHeight={193} initialWidth={92} {...props}>
    <g clipPath="url(#clip0_19425_3756)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.8865 46.4929C32.4565 52.0129 28.4565 58.3829 18.8865 65.0029C15.8065 67.1329 13.7965 68.8029 13.1565 69.0829C13.1565 69.0829 13.2465 55.7429 13.2465 55.3029C13.2465 53.6829 18.5265 52.2429 15.8265 39.3029C15.6012 37.9267 15.4676 36.5369 15.4265 35.1429C15.1665 31.6129 12.3165 31.0229 11.0065 33.5429C10.3765 34.7429 10.3865 37.2429 10.5165 38.6629C10.6365 39.8929 9.43648 39.0729 4.94648 39.2729C4.47415 39.2528 4.00608 39.3697 3.59872 39.6096C3.19136 39.8495 2.8621 40.2022 2.65067 40.625C2.43925 41.0479 2.35469 41.5229 2.40717 41.9927C2.45966 42.4626 2.64696 42.9072 2.94648 43.2729C3.05648 43.4229 -0.0535202 44.4329 1.94648 47.5529C-0.573521 50.3529 2.72648 51.7629 2.80648 52.2529C3.41648 56.0429 0.746479 75.0929 2.25648 78.9829C5.78648 88.0629 16.7765 82.4629 31.4865 71.3329C36.5965 67.4729 39.4865 55.8229 38.0065 49.5829C37.9265 49.3829 37.8865 46.4829 37.8865 46.4929Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M37.8865 46.4929C32.4565 52.0129 28.4565 58.3829 18.8865 65.0029C15.8065 67.1329 13.7965 68.8029 13.1565 69.0829C13.1565 69.0829 13.2465 55.7429 13.2465 55.3029C13.2465 53.6829 18.5265 52.2429 15.8265 39.3029C15.6012 37.9267 15.4676 36.5369 15.4265 35.1429C15.1665 31.6129 12.3165 31.0229 11.0065 33.5429C10.3765 34.7429 10.3865 37.2429 10.5165 38.6629C10.6365 39.8929 9.43648 39.0729 4.94648 39.2729C4.47415 39.2528 4.00608 39.3697 3.59872 39.6096C3.19135 39.8495 2.8621 40.2022 2.65067 40.625C2.43925 41.0479 2.35469 41.5229 2.40717 41.9927C2.45966 42.4626 2.64696 42.9072 2.94648 43.2729C3.05648 43.4229 -0.0535202 44.4329 1.94648 47.5529C-0.57352 50.3529 2.72648 51.7629 2.80648 52.2529C3.41648 56.0429 0.746477 75.0929 2.25648 78.9829C5.78648 88.0629 16.7765 82.4629 31.4865 71.3329C36.5965 67.4729 39.4865 55.8229 38.0065 49.5829C37.9265 49.3829 37.8865 46.4829 37.8865 46.4929Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1469 68.3731C13.1469 68.8231 13.1469 72.5531 13.1469 73.6631C13.2053 72.0373 13.6075 70.4423 14.3268 68.9831C14.3268 68.9831 15.0269 67.7431 14.8669 67.8831C14.3768 68.2183 13.8542 68.503 13.3069 68.7331C13.2869 68.6731 13.1869 68.3831 13.1469 68.3731Z"
        fill="#2A2F3C"
      />
      <path
        d="M13.1469 68.3731C13.1469 68.8231 13.1469 72.5531 13.1469 73.6631C13.2053 72.0373 13.6075 70.4423 14.3268 68.9831C14.3268 68.9831 15.0269 67.7431 14.8669 67.8831C14.3768 68.2183 13.8542 68.503 13.3069 68.7331C13.2869 68.6731 13.1869 68.3831 13.1469 68.3731Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.69659 39.3931C12.6966 39.7531 13.4866 43.9431 9.57658 43.7631C8.04658 43.6931 6.06659 43.5231 6.19659 43.5531C6.67659 43.6631 12.7466 43.9831 10.9366 46.6631C9.79658 48.3531 5.04658 47.6631 5.20658 47.6631C6.22596 47.7615 7.22062 48.0356 8.14658 48.4731C10.9566 49.5931 10.2366 51.9631 7.48658 52.2031C6.78658 52.2631 5.77658 52.2831 5.04658 52.2831"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.6898 44.8899L36.3198 48.0499C34.3198 57.3299 31.3198 62.7699 29.7598 71.8599C31.7598 70.1999 33.1998 68.8599 35.2098 67.1599L39.6898 44.8899Z"
        fill="#2A2F3C"
      />
      <path
        d="M39.5 44.5L36.5 48C34.5 57.28 31.06 63.41 29.5 72.5C31.5 70.84 33.2 68.86 35.21 67.16L39.5 44.5Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.1202 175.37H55.1202C55.2884 175.375 55.4483 175.444 55.5672 175.563C55.6862 175.682 55.7552 175.842 55.7602 176.01L56.7602 190.17H23.1302C22.5002 184.24 26.8502 182.44 31.9702 179.9C34.0002 178.43 34.1202 175.37 34.1202 175.37Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M34.1202 175.37H55.1202C55.2884 175.375 55.4483 175.444 55.5672 175.563C55.6862 175.682 55.7552 175.842 55.7602 176.01L56.7602 190.17H23.1302C22.5002 184.24 26.8502 182.44 31.9702 179.9C34.0002 178.43 34.1202 175.37 34.1202 175.37Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.12 187.9H23.14C22.7865 187.9 22.5 188.186 22.5 188.54V191.57C22.5 191.923 22.7865 192.21 23.14 192.21H57.12C57.4735 192.21 57.76 191.923 57.76 191.57V188.54C57.76 188.186 57.4735 187.9 57.12 187.9Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M57.12 187.9H23.14C22.7865 187.9 22.5 188.186 22.5 188.54V191.57C22.5 191.923 22.7865 192.21 23.14 192.21H57.12C57.4735 192.21 57.76 191.923 57.76 191.57V188.54C57.76 188.186 57.4735 187.9 57.12 187.9Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.7999 175.37H75.7999C75.9673 175.377 76.1258 175.447 76.2443 175.566C76.3628 175.684 76.4326 175.843 76.4399 176.01L77.4399 190.17H43.8099C43.1799 184.24 47.5299 182.44 52.6499 179.9C54.6399 178.43 54.7999 175.37 54.7999 175.37Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M54.7999 175.37H75.7999C75.9673 175.377 76.1258 175.447 76.2443 175.566C76.3628 175.684 76.4326 175.843 76.4399 176.01L77.4399 190.17H43.8099C43.1799 184.24 47.5299 182.44 52.6499 179.9C54.6399 178.43 54.7999 175.37 54.7999 175.37Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.7997 187.9H43.8197C43.4662 187.9 43.1797 188.186 43.1797 188.54V191.57C43.1797 191.923 43.4662 192.21 43.8197 192.21H77.7997C78.1532 192.21 78.4397 191.923 78.4397 191.57V188.54C78.4397 188.186 78.1532 187.9 77.7997 187.9Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M77.7997 187.9H43.8197C43.4662 187.9 43.1797 188.186 43.1797 188.54V191.57C43.1797 191.923 43.4662 192.21 43.8197 192.21H77.7997C78.1532 192.21 78.4397 191.923 78.4397 191.57V188.54C78.4397 188.186 78.1532 187.9 77.7997 187.9Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.0002 14.7501C40.0002 23.3001 40.0002 23.1601 40.0002 31.7501C40.0002 54.3501 29.7202 59.1201 29.0702 82.3801C28.9502 86.6301 32.7602 86.1001 33.4002 91.8101C38.2102 92.9801 51.0502 88.2801 55.8502 89.4501C60.6402 88.2801 73.2802 97.7801 78.0702 96.6101C86.4802 59.6801 68.7302 52.4901 68.7602 33.2501V14.7901C68.8202 -4.19991 40.0602 -3.54991 40.0002 14.7501Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M40.0002 14.7501C40.0002 23.3001 40.0002 23.1601 40.0002 31.7501C40.0002 54.3501 29.7202 59.1201 29.0702 82.3801C28.9502 86.6301 32.7602 86.1001 33.4002 91.8101C38.2102 92.9801 51.0502 88.2801 55.8502 89.4501C60.6402 88.2801 73.2802 97.7801 78.0702 96.6101C86.4802 59.6801 68.7302 52.4901 68.7602 33.2501V14.7901C68.8202 -4.19991 40.0602 -3.54991 40.0002 14.7501Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.0001 14.75C40.0001 23.3 40.0001 23.16 40.0001 31.75C40.0082 34.2627 39.8679 36.7737 39.5801 39.27H69.3501C68.9499 37.2952 68.7522 35.2848 68.7601 33.27V14.81C68.8201 -4.20003 40.0601 -3.55003 40.0001 14.75Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M40.0001 14.75C40.0001 23.3 40.0001 23.16 40.0001 31.75C40.0082 34.2627 39.8679 36.7737 39.5801 39.27H69.3501C68.9499 37.2952 68.7522 35.2848 68.7601 33.27V14.81C68.8201 -4.20003 40.0601 -3.55003 40.0001 14.75Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.7303 13.2301C67.3003 -3.63994 41.6304 -3.08994 40.1104 13.2301H68.7303Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M68.7303 13.2301C67.3003 -3.63994 41.6304 -3.08994 40.1104 13.2301H68.7303Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.0501 81.72C80.0501 81.93 80.0501 82.13 80.0501 82.34C80.2301 92.01 77.1101 97.34 77.14 106.17C77.14 125.32 77.7201 174.55 77.6601 175.55H33.0001C33.0001 175.55 32.6 125.44 32.88 106.66C33.02 96.66 30.1201 93.8 29.0401 82.45C29.0251 82.2069 29.0251 81.9631 29.0401 81.72H80.0501Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M80.0501 81.72C80.0501 81.93 80.0501 82.13 80.0501 82.34C80.2301 92.01 77.11 97.34 77.14 106.17C77.14 125.32 77.7201 174.55 77.6601 175.55H33.0001C33.0001 175.55 32.6 125.44 32.88 106.66C33.02 96.66 30.1201 93.8 29.0401 82.45C29.0251 82.2069 29.0251 81.9631 29.0401 81.72H80.0501Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.3101 24.7101C41.6387 24.71 41.9599 24.613 42.2336 24.4312C42.5073 24.2494 42.7213 23.9909 42.8488 23.6881C42.9763 23.3853 43.0116 23.0516 42.9504 22.7287C42.8891 22.4059 42.734 22.1084 42.5044 21.8733C42.2748 21.6382 41.981 21.4761 41.6598 21.4072C41.3385 21.3383 41.004 21.3658 40.6983 21.486C40.3925 21.6063 40.129 21.8142 39.9408 22.0835C39.7526 22.3528 39.648 22.6716 39.6401 23.0001C39.6348 23.2228 39.674 23.4442 39.7556 23.6515C39.8371 23.8587 39.9593 24.0476 40.1149 24.2069C40.2705 24.3663 40.4564 24.4929 40.6617 24.5793C40.8669 24.6657 41.0874 24.7102 41.3101 24.7101Z"
        fill="white"
      />
      <path
        d="M41.3101 24.7101C41.6387 24.71 41.9599 24.613 42.2336 24.4312C42.5073 24.2494 42.7213 23.9909 42.8488 23.6881C42.9763 23.3853 43.0116 23.0516 42.9504 22.7287C42.8891 22.4059 42.734 22.1084 42.5044 21.8733C42.2748 21.6382 41.981 21.4761 41.6598 21.4072C41.3385 21.3383 41.004 21.3658 40.6983 21.486C40.3925 21.6063 40.129 21.8142 39.9408 22.0835C39.7526 22.3528 39.648 22.6716 39.6401 23.0001C39.6348 23.2228 39.674 23.4442 39.7556 23.6515C39.8371 23.8587 39.9593 24.0476 40.1149 24.2069C40.2705 24.3663 40.4564 24.4929 40.6617 24.5793C40.8669 24.6657 41.0874 24.7102 41.3101 24.7101V24.7101Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.3297 21.3701C54.9977 21.3702 54.6733 21.4692 54.3979 21.6546C54.1224 21.8399 53.9085 22.1031 53.7833 22.4106C53.6582 22.7181 53.6276 23.0559 53.6953 23.3809C53.763 23.7059 53.9261 24.0033 54.1637 24.2352C54.4013 24.4671 54.7026 24.6229 55.0291 24.6828C55.3556 24.7426 55.6926 24.7038 55.997 24.5712C56.3013 24.4387 56.5593 24.2184 56.7379 23.9386C56.9165 23.6587 57.0076 23.332 56.9997 23.0001C56.9892 22.5642 56.8087 22.1496 56.4966 21.845C56.1846 21.5404 55.7658 21.37 55.3297 21.3701Z"
        fill="white"
      />
      <path
        d="M55.3297 21.3701C54.9977 21.3702 54.6733 21.4692 54.3979 21.6546C54.1224 21.8399 53.9085 22.1031 53.7833 22.4106C53.6582 22.7181 53.6276 23.0559 53.6953 23.3809C53.763 23.7059 53.9261 24.0033 54.1637 24.2352C54.4013 24.4671 54.7026 24.6229 55.0291 24.6828C55.3556 24.7426 55.6926 24.7038 55.997 24.5712C56.3013 24.4387 56.5593 24.2184 56.7379 23.9386C56.9165 23.6587 57.0076 23.332 56.9997 23.0001C56.9892 22.5642 56.8087 22.1496 56.4966 21.845C56.1846 21.5404 55.7658 21.37 55.3297 21.3701Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.6099 106.87C54.6099 102.78 47.8899 103.16 45.4399 97.3501"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.9398 16.5601C41.2809 16.4618 40.6077 16.5664 40.0098 16.8601"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M54.6299 16.56C55.2887 16.4636 55.9614 16.5682 56.5599 16.86"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.52 36.6399H40C39.4696 36.6399 38.9609 36.8506 38.5858 37.2257C38.2107 37.6008 38 38.1095 38 38.6399C38 39.1703 38.2107 39.679 38.5858 40.0541C38.9609 40.4292 39.4696 40.6399 40 40.6399H68.5C69.0304 40.6399 69.5391 40.4292 69.9142 40.0541C70.2893 39.679 70.5 39.1703 70.5 38.6399C70.5 38.1095 70.2893 37.6008 69.9142 37.2257C69.5391 36.8506 69.0304 36.6399 68.5 36.6399H68.52Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M68.52 36.6399H40C39.4696 36.6399 38.9609 36.8506 38.5858 37.2257C38.2107 37.6008 38 38.1095 38 38.6399C38 39.1703 38.2107 39.679 38.5858 40.0541C38.9609 40.4292 39.4696 40.6399 40 40.6399H68.5C69.0304 40.6399 69.5391 40.4292 69.9142 40.0541C70.2893 39.679 70.5 39.1703 70.5 38.6399C70.5 38.1095 70.2893 37.6008 69.9142 37.2257C69.5391 36.8506 69.0304 36.6399 68.5 36.6399H68.52Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M68.7902 13.0901H24.9502"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.1801 105.4L68.6601 66.8201C68.5501 67.5701 69.5601 74.7101 70.5001 81.7201C70.5001 81.9801 71.5001 81.4201 71.5601 81.7201C73.3801 95.2801 76.6401 112.04 74.6201 125.4C74.0301 129.31 76.8401 130.33 76.7001 130.04C76.0365 128.541 75.8718 126.869 76.2301 125.27C76.5901 123.27 77.9101 122.27 77.2301 121.27L77.1801 105.4Z"
        fill="#2A2F3C"
      />
      <path
        d="M77.1801 105.4L68.6601 66.8201C68.5501 67.5701 69.5601 74.7101 70.5001 81.7201C70.5001 81.9801 71.5001 81.4201 71.5601 81.7201C73.3801 95.2801 76.6401 112.04 74.6201 125.4C74.0301 129.31 76.8401 130.33 76.7001 130.04C76.0365 128.541 75.8718 126.869 76.2301 125.27C76.5901 123.27 77.9101 122.27 77.2301 121.27L77.1801 105.4Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.6299 40.5901L39.1899 42.3601C44.7549 41.4609 50.3679 40.8899 55.9999 40.6501L39.6299 40.5901Z"
        fill="#2A2F3C"
      />
      <path
        d="M39.6299 40.5901L39.1899 42.3601C44.7549 41.4609 50.3679 40.8899 55.9999 40.6501L39.6299 40.5901Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.9398 175.51L33.5098 177.28C39.0646 176.382 44.6677 175.814 50.2898 175.58L33.9398 175.51Z"
        fill="#2A2F3C"
      />
      <path
        d="M33.9398 175.51L33.5098 177.28C39.0646 176.382 44.6677 175.814 50.2898 175.58L33.9398 175.51Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.8997 175.51L54.4697 177.28C60.0245 176.382 65.6277 175.814 71.2497 175.58L54.8997 175.51Z"
        fill="#2A2F3C"
      />
      <path
        d="M54.8997 175.51L54.4697 177.28C60.0245 176.382 65.6277 175.814 71.2497 175.58L54.8997 175.51Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.48 13L40 14.79C45.5583 13.8916 51.1647 13.3206 56.79 13.08L40.48 13Z"
        fill="#2A2F3C"
      />
      <path
        d="M40.48 13L40 14.79C45.5583 13.8916 51.1647 13.3206 56.79 13.08L40.48 13Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.5601 23.2C40.5105 23.2024 40.4636 23.2232 40.4285 23.2583C40.3933 23.2934 40.3725 23.3403 40.3701 23.39C40.3701 23.4403 40.3901 23.4887 40.4258 23.5243C40.4614 23.5599 40.5097 23.58 40.5601 23.58C40.5854 23.5814 40.6108 23.5775 40.6345 23.5685C40.6582 23.5594 40.6797 23.5455 40.6977 23.5275C40.7156 23.5096 40.7296 23.4881 40.7386 23.4643C40.7476 23.4406 40.7516 23.4153 40.7501 23.39C40.7501 23.3396 40.7301 23.2912 40.6945 23.2556C40.6588 23.22 40.6105 23.2 40.5601 23.2Z"
        fill="#2A2F3C"
      />
      <path
        d="M40.5601 23.2C40.5105 23.2024 40.4636 23.2232 40.4285 23.2583C40.3933 23.2934 40.3725 23.3403 40.3701 23.39C40.3701 23.4403 40.3901 23.4887 40.4258 23.5243C40.4614 23.5599 40.5097 23.58 40.5601 23.58C40.5854 23.5814 40.6108 23.5775 40.6345 23.5685C40.6582 23.5594 40.6797 23.5455 40.6977 23.5275C40.7156 23.5096 40.7296 23.4881 40.7386 23.4643C40.7476 23.4406 40.7516 23.4153 40.7501 23.39C40.7501 23.3396 40.7301 23.2912 40.6945 23.2556C40.6588 23.22 40.6105 23.2 40.5601 23.2V23.2Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.3999 23.6C54.4503 23.6 54.4987 23.58 54.5343 23.5443C54.5699 23.5087 54.59 23.4604 54.59 23.41C54.59 23.3596 54.5699 23.3113 54.5343 23.2756C54.4987 23.24 54.4503 23.22 54.3999 23.22C54.3496 23.22 54.3012 23.24 54.2656 23.2756C54.23 23.3113 54.21 23.3596 54.21 23.41C54.21 23.4604 54.23 23.5087 54.2656 23.5443C54.3012 23.58 54.3496 23.6 54.3999 23.6Z"
        fill="#2A2F3C"
      />
      <path
        d="M54.3999 23.6C54.4503 23.6 54.4987 23.58 54.5343 23.5443C54.5699 23.5087 54.59 23.4604 54.59 23.41C54.59 23.3596 54.5699 23.3113 54.5343 23.2756C54.4987 23.24 54.4503 23.22 54.3999 23.22C54.3496 23.22 54.3012 23.24 54.2656 23.2756C54.23 23.3113 54.21 23.3596 54.21 23.41C54.21 23.4604 54.23 23.5087 54.2656 23.5443C54.3012 23.58 54.3496 23.6 54.3999 23.6Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.5801 175.55V106.74"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.19 130.46C84.88 130.27 82.66 135.91 83.03 136.95C83.73 138.95 86.71 137.05 87.28 136.14C87.56 135.69 91.2801 138.73 91.5201 130.62C91.6101 128.06 89.8201 118.48 89.6301 115C88.76 99.0499 83.02 59.1899 69.7 42.4399C68.7 41.1899 67.86 42.0299 67.03 42.2199C65.73 42.5199 64.54 43.3399 63.26 47.8699C61.91 52.6499 64.09 64.1799 68.2 66.8199C71.31 81.2899 76.1 98.8899 76.7701 113.76C76.9101 116.94 77.2801 119.17 76.7701 121.01C76.34 122.56 75.1201 123.66 74.3201 126.56C73.7801 128.56 76.62 133.32 78.12 132.65C79.12 132.21 79.33 131.49 80.39 128.13C80.65 127.29 81.93 124.01 82.51 123.74C83.85 123 84.41 128.46 84.18 130.46"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M84.2301 129.31C84.2726 131.441 83.853 133.556 83.0001 135.51C82.4601 136.88 83.3301 137.51 84.3301 137.58C85.7301 137.73 86.8201 136.72 87.8701 133.93C88.6595 131.537 88.9687 129.012 88.7801 126.5C88.6601 125.06 90.4901 124.7 90.3201 122.5C89.0801 111.85 87.6201 69.7299 70.0801 42.1699"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.19 130.46C84.42 128.46 83.86 123 82.52 123.74C81.94 124.01 80.6599 127.29 80.3999 128.13C79.9799 129.47 79.8599 131.05 79.5399 131.77C78.4699 134.22 75.25 131.05 74.71 128.86C73.71 124.65 76.59 121.55 76.78 121.01C77.41 119.19 76.87 116.01 76.78 113.76C76.11 98.8901 71.32 81.2901 68.21 66.8201"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.0497 124.83C84.9197 127.83 84.0497 136.29 81.2897 137.93C79.9497 138.74 78.1597 138.11 77.9497 137.23C77.4597 135.1 78.6097 134.54 79.1697 131.46C79.6404 128.776 79.6742 126.034 79.2697 123.34C78.8797 121.26 81.5397 119.82 83.4897 123.54C83.7287 123.945 83.9167 124.379 84.0497 124.83Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M84.0497 124.83C84.9197 127.83 84.0497 136.29 81.2897 137.93C79.9497 138.74 78.1597 138.11 77.9497 137.23C77.4597 135.1 78.6097 134.54 79.1697 131.46C79.6404 128.776 79.6742 126.034 79.2697 123.34C78.8797 121.26 81.5397 119.82 83.4897 123.54C83.7287 123.945 83.9167 124.379 84.0497 124.83V124.83Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M89.9103 119C89.8103 119.54 91.7703 126.37 91.3803 131.17C91.1703 133.84 89.5403 137.3 87.2803 136.1"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.1896 117.72C81.1224 117.777 80.1194 118.247 79.3932 119.031C78.667 119.815 78.2747 120.852 78.2996 121.92C78.2631 122.442 78.3311 122.966 78.4997 123.461C78.6683 123.956 78.9341 124.413 79.2816 124.804C79.629 125.195 80.051 125.513 80.5229 125.739C80.9948 125.965 81.5071 126.095 82.0296 126.12C83.0968 126.063 84.0999 125.593 84.8261 124.809C85.5523 124.025 85.9446 122.988 85.9196 121.92C85.9562 121.398 85.8882 120.874 85.7196 120.379C85.551 119.884 85.2851 119.427 84.9377 119.036C84.5903 118.645 84.1683 118.327 83.6964 118.101C83.2245 117.875 82.7122 117.745 82.1896 117.72Z"
        fill={colorTokens['chubbs-body']}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.8603 118.44C88.0403 118.44 85.0303 125.84 87.9803 126.12C90.9303 126.4 89.1303 131.12 89.9803 131.12C90.8303 131.12 90.9803 128.48 90.9803 127.26C90.9803 126.04 89.6703 118.41 88.8603 118.41"
        fill={colorTokens['chubbs-body']}
      />
      <path
        d="M79.3997 125.41C79.3997 124.54 78.6497 124.26 78.5097 125.59C78.4123 126.738 78.7009 127.885 79.3298 128.85"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M41.43 18.4699C42.3157 18.462 43.1839 18.7174 43.9242 19.2037C44.6645 19.69 45.2437 20.3854 45.5882 21.2014C45.9326 22.0175 46.0269 22.9175 45.8589 23.7872C45.691 24.6569 45.2685 25.4571 44.6449 26.0863C44.0214 26.7154 43.225 27.1451 42.3569 27.3209C41.4887 27.4966 40.5879 27.4105 39.7688 27.0733C38.9497 26.7362 38.2492 26.1633 37.7562 25.4274C37.2632 24.6915 37 23.8257 37 22.9399C37 21.7613 37.4654 20.6303 38.2951 19.7932C39.1247 18.956 40.2514 18.4805 41.43 18.4699V18.4699Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M55.1302 18.47C56.0142 18.47 56.8785 18.7321 57.6136 19.2233C58.3486 19.7145 58.9216 20.4126 59.2599 21.2294C59.5982 22.0462 59.6867 22.9449 59.5143 23.812C59.3418 24.6791 58.9161 25.4756 58.2909 26.1007C57.6658 26.7259 56.8693 27.1516 56.0022 27.3241C55.1351 27.4966 54.2363 27.408 53.4196 27.0697C52.6028 26.7314 51.9047 26.1585 51.4135 25.4234C50.9223 24.6883 50.6602 23.8241 50.6602 22.94C50.6602 21.7545 51.1311 20.6175 51.9694 19.7792C52.8077 18.9409 53.9446 18.47 55.1302 18.47V18.47Z"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M59.8301 22.3401H68.7801"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M45.4297 21.0001C46.4297 19.1801 49.9497 19.0001 51.3697 20.8301"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
      <path
        d="M46 30H51.06C51.06 30.671 50.7934 31.3145 50.319 31.789C49.8445 32.2634 49.201 32.53 48.53 32.53C47.859 32.53 47.2155 32.2634 46.741 31.789C46.2666 31.3145 46 30.671 46 30Z"
        fill="#2A2F3C"
        stroke="#2A2F3C"
        strokeWidth="0.57"
        strokeMiterlimit="22.93"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_19425_3756">
        <rect width="92" height="193" fill="white" />
      </clipPath>
    </defs>
  </ChubbsWrapper>
));
