import type { FC } from 'react';
import { memo } from 'react';
import { type ChubbsProps } from '@/components/ui/Chubbs/chubbs.typedefs';
import { Chubbs } from '@/components/ui/Chubbs/Chubbs';
import {
  ChubbsLikeLight,
} from '@/components/ui/Chubbs/ChubbsLike/ChubbsLike.light';
import {
  ChubbsLikeDark,
} from '@/components/ui/Chubbs/ChubbsLike/ChubbsLike.dark';

export const ChubbsLike: FC<ChubbsProps> = memo((props) => (
  <Chubbs
    LightChubbs={ChubbsLikeLight}
    DarkChubbs={ChubbsLikeDark}
    {...props}
  />
));
